/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { FC, useMemo, useRef, useState, useEffect } from 'react';
import { globalHistory } from '@reach/router';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import Headroom from 'react-headroom';
import { Hatch_ContentMenuItemType } from '../graphqlTypes';
import { flatListToHierarchical } from '../utils/flatListToHierarchical';
import { getResponsiveType } from '../utils/getResponsiveType';
import { Telephone } from './Telephone';
import {
    spaceItems,
    noScrollbar,
    verticalContainers,
    isBrowser,
} from '../utils';
import { useClickOutside } from '../hooks';
import { Typography } from './Typography';
import { FeLogoBlack } from './icons/FeLogoBlack';
import { FeLogoEggsBlack } from './icons/FeLogoEggsBlack';
import { Close } from './icons';
import { IconButton } from './IconButton';
import { TextLink } from './TextLink';

interface HeaderProps {
    siteTitle?: string;
    menu: [Hatch_ContentMenuItemType];
}

interface HierarchicalMenuProps extends Hatch_ContentMenuItemType {
    childMenuItems: [HierarchicalMenuProps];
}

interface NavItemProps {
    menu: [HierarchicalMenuProps];
    handleSetActiveDropDown: (evt: any, index: number) => void;
    activeDropDown: number | undefined;
}

interface StyledHeaderProps {
    isPinned?: boolean;
}

const StyledHeader = styled.header<StyledHeaderProps>`
    ${({ theme: { media, colors, layers, space }, isPinned }) => css`
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        /* transition: all 0.2s ease-in; */
        background: ${colors.white.default};
        height: 120px;
        z-index: ${layers.header};
        border-bottom: 1px solid ${colors.white.default};

        @media (min-width: ${media.xl}) {
            height: 118px;
            flex-flow: row nowrap;
            justify-content: flex-end;
            align-items: center;
            padding: ${space.md} 0;
            background: ${colors.white.default};
        }
        ${isPinned &&
        css`
            position: fixed;
            height: 52px;
            @media (min-width: ${media.xl}) {
                height: 72px;
                border-bottom: 1px solid ${colors.grey2.default};
            }
        `}
    `}
`;

const StyledHeaderContainer = styled.div<StyledHeaderProps>`
    ${({ theme: { media, colors }, isPinned }) => css`
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        /* transition: all 0.2s ease-in; */
        height: 120px;

        ${isPinned &&
        css`
            position: fixed;
            height: 52px;
            @media (min-width: ${media.xl}) {
                height: 72px;
                border-bottom: 1px solid ${colors.grey2.default};
            }
        `}

        @media (min-width: ${media.xl}) {
                max-width: 1140px;
                margin: 0 auto;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
            }
    `}
`;

const StyledHeaderInner = styled.div<StyledHeaderProps>`
    ${({ theme: { media, layers, space }, isPinned }) => css`
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        width: 100%;
        margin: 0 auto;
        padding: 0 ${space.md};
        transition: all 0.2s ease-in;
        z-index: ${layers.header};
        ${isPinned &&
        css`
            display: none;
        `}
        @media (min-width: ${media.xl}) {
            width: auto;
            margin: 0;
            padding: 0;
            ${isPinned &&
            css`
                display: flex;
            `}
        }
    `};
`;

const StyledLogo = styled(Link)<StyledHeaderProps>`
    ${({ theme: { media }, isPinned }) => css`
        padding: 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: relative;
        height: 31px;
        width: 42px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        @media (min-width: ${media.xl}) {
            height: 88px;
            width: 83px;

            ${isPinned &&
            css`
                height: 40px;
            `}
        }
    `};
`;

const StyledLogoSm = styled.span<StyledHeaderProps>`
    ${({ theme: { media }, isPinned }) => css`
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        display: none;
        transition: opacity 0.2s ease-in;
        height: 31px;
        @media (min-width: ${media.xl}) {
            transform: translate(-50%, -400%);
            display: block;
            opacity: 0;
            ${isPinned &&
            css`
                transform: translateY(-50%);
                opacity: 1;
            `}
        }
    `}
`;
const StyledLogoLg = styled.span<StyledHeaderProps>`
    ${({ theme: { media }, isPinned }) => css`
        position: absolute;
        top: 10%;
        left: 0;
        display: block;
        transition: opacity 0.2s ease-in;
        transform: translateY(-50%);
        height: 35px;

        @media (min-width: ${media.xl}) {
            transform: translateY(-50%);
            display: block;
            opacity: 1;
            height: 60px;
            top: 50%;

            ${isPinned &&
            css`
                transform: translate(-50%, -400%);
                opacity: 0;
            `}
        }
    `}
`;

const StyledClose = styled(IconButton)`
    ${({ theme, theme: { maxMedia, space } }) => css`
        display: none;
        .is-active-dropdown & {
            @media (max-width: ${maxMedia.md}) {
                color: inherit;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
            }
        }

        .label {
            text-decoration: none;
            ${getResponsiveType('body')}
        }

        .close-icon {
            display: block;
            width: 14px;
            height: 14px;
            margin-left: ${space.sm};
            svg {
                display: block;
                width: 14px;
                height: 14px;
            }
        }
    `};
`;
const StyledCallSm = styled.div`
    ${({ theme, theme: { media, maxMedia, space, colors } }) => css`
        display: block;
        color: ${colors.black.default};
        @media (min-width: ${media.xl}) {
            display: none;
        }

        .link {
            color: inherit;
            display: flex;
            flex-flow: row nowrap;
            .is-active-dropdown & {
                @media (max-width: ${maxMedia.md}) {
                    display: none;
                }
            }
        }
        .icon {
            display: block;
            width: 14px;
            height: 26px;
            border-radius: 4px;
            margin-left: ${space.sm};
        }

        .number {
            display: none;
            @media (min-width: ${media.md}) {
                display: inline;
            }
        }
        .label {
            text-decoration: none;
            ${getResponsiveType('body')}
            @media (min-width: ${media.md}) {
                display: none;
            }
        }
    `};
`;
const StyledCallLg = styled.div`
    ${({ theme: { media, colors } }) => css`
        display: none;
        color: ${colors.black.default};
        /* height: 40px; */ 

        @media (min-width: ${media.xl}) {
            display: block;
        }

        a {
            text-decoration: none;
            color: ${colors.black.default};
            ${getResponsiveType('h3')}
        }
    `};
`;

const StyledTelephoneSm = styled.div`
    ${({ theme: { media, colors } }) => css`
        &:hover .icon {
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }

        @keyframes shake {
            10%,
            90% {
                transform: translate3d(-1px, 0, 0);
            }

            20%,
            80% {
                transform: translate3d(2px, 0, 0);
            }

            30%,
            50%,
            70% {
                transform: translate3d(-4px, 0, 0);
            }

            40%,
            60% {
                transform: translate3d(4px, 0, 0);
            }
        }
    `};
`;
const StyledHeadNav = styled.div`
    ${({ theme: { media, colors, space } }) => css`
        white-space: nowrap;

        overflow: hidden;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        border-top: 1px solid ${colors.grey2.default};
        border-bottom: 1px solid ${colors.grey2.default};

        .header-pinned & {
            min-height: 50px;
        }
        ${noScrollbar()};
        @media (min-width: ${media.md}) {
            overflow: visible;
        }
        @media (min-width: ${media.xl}) {
            display: flex;
            flex-grow: 1;
            justify-content: flex-end;
            margin: 0 ${space.xl};
            border: none;
            width: auto;
        }
    `};
`;

const StyledPrimaryNav = styled.nav`
    ${({
        theme: { maxMedia, media, colors, layers, space, borderRadii, shadows },
    }) => css`
        .primary-nav__nav {
            list-style: none;
            margin: 0 0 0 6px;
            padding: 0 0 0 6px;
            text-align: center;
            overflow-x: auto;
            overflow-y: visible;
            -webkit-overflow-scrolling: touch;
            background-color: ${colors.white.default};
            > * + * {
                margin-left: ${space.md};
            }

            @media (max-width: ${maxMedia.xl}) {
                .is-active-dropdown & {
                    overflow-x: visible;
                }
            }
            @media (min-width: ${media.md}) {
                overflow-x: visible;
            }
            @media (min-width: ${media.xl}) {
                min-height: 40px;
                text-align: left;
            }
        }
        .primary-nav__item {
            display: inline-block;
            @media (min-width: ${media.xl}) {
                min-height: 40px;
            }
        }
        .primary-nav__link {
            display: block;
            padding: 11px 0;
            //margin: 0 ${space.sm};
            color: ${colors.black.default};
            min-height: 50px;
            text-decoration: none;
            ${getResponsiveType('body')}
            position: relative;

            &:hover {
                text-decoration: underline;
            }

            @media (min-width: ${media.xl}) {
                &.is-active-dropdown.is-dropdown {
                    &:after {
                        bottom: -50px;
                    }
                }
                padding: ${space.sm} 0;
                //margin: 0 ${space.xs};
            }
            @media (min-width: 1350px) {
                &.is-active-dropdown.is-dropdown {
                    &:after {
                        border-bottom-color: ${colors.white.default};
                        bottom: -50px;
                    }
                }
            }
            &.is-active {
                font-weight: bold;
                color: ${colors.accent2.default};
                &:before {
                    visibility: visible;
                    transform: scaleX(1);
                }
            }
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 14px;
                left: 0;
                background-color: ${colors.accent2.default};
                visibility: hidden;
                transform: scaleX(0);
                transition: all 0.3s ease-in-out 0.4s;
                @media (min-width: ${media.xl}) {
                    bottom: 10px;
                }
            }
            &:hover,
            &:focus {
                text-decoration: none;
                color: ${colors.accent2.default};
            }
        }
        .primary-nav__dropdown {
            display: flex;
            flex-flow: column nowrap;
            position: fixed;
            top: 63px;
            bottom: 0;
            left: 50%;
            right: auto;
            width: 100%;
            background-color: #ffffff;
            min-height: 200px;
            transition: opacity 0.4s ease-in-out, width 0.2s ease-in-out;
            white-space: normal;
            text-align: left;
            opacity: 1;
            transform: translate(-50%, -170%);
            max-width: 100%;
            width: 100%;
            overflow: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            z-index: ${layers.dropDownSm};
            @media (min-width: ${media.sm}) {
                max-width: 720px;
            }
            @media (min-width: ${media.md}) {
                z-index: ${layers.dropDown};
                height: auto;
                bottom: auto;
                top: 120px;
                flex-flow: row nowrap;
                text-align: left;
                max-width: 960px;
            }

            @media (min-width: ${media.xl}) {
                border-radius: ${borderRadii.base};
                box-shadow: ${shadows.default};
                top: calc(118px + ${space.sm});
                width: 100%;
                max-width: 1140px;
            }
            .header-pinned & {
                @media (min-width: ${media.xl}) {
                    top: calc(72px + ${space.lg});
                }
            }
            &.is-active {
                //  animation: 390ms 150ms 1 both appear;
                transform: translate(-50%, 0);
                opacity: 1;
                @media (max-width: ${maxMedia.sm}) {
                    padding-bottom: ${space.lg};
                }
            }
            .primary-nav__dropdown__intro,
            .primary-nav__dropdown__items {
                ${verticalContainers()};
                ${spaceItems('md')};
                flex-basis: auto;
            }
            .primary-nav__dropdown__intro {
                background-color: ${colors.accent2.default};
                color: ${colors.white.default};
                width: 100%;
                flex-shrink: 1;
                a:hover {
                    color: ${colors.white.default};
                }
                @media (min-width: ${media.md}) {
                    flex-basis: 35%;
                    max-width: 35%;
                }
                @media (min-width: ${media.xl}) {
                    flex-basis: 32%;
                    max-width: 32%;
                }
            }
            .primary-nav__dropdown__items {
                background-color: ${colors.white.default};
                color: ${colors.accent2.default};
                width: 100%;
                @media (min-width: ${media.md}) {
                    width: 65%;
                    flex-basis: 65%;
                }
                @media (min-width: ${media.xl}) {
                    width: 68%;
                    flex-basis: 68%;
                }
                .primary-nav__dropdown__heading {
                    color: ${colors.grey1.default};
                }
            }

            .primary-nav__dropdown__content {
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .primary-nav__dropdown__item {
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.7;

                list-style: none;
                margin-top: 0;
                display: inline-block;
                //margin-bottom: 1.13333rem;
                width: 100%;
                .primary-nav__dropdown__link {
                    ${getResponsiveType('body')}
                }
            }
            .primary-nav__dropdown__nav {
                padding: 0;
                @media (min-width: ${media.md}) {
                    column-count: 2;
                }
            }
            .primary-nav__item {
                display: inline-block;
                width: 100%;
                margin-bottom: ${space.md};
            }
            .primary-nav__link {
                display: block;
                padding: 0;
            }
        }
    `};
`;
const getJsHandle = (item: HierarchicalMenuProps) =>
    `js-${item?.title?.replace(' ', '-')}`;
const NavItems = ({
    menu,
    handleSetActiveDropDown,
    activeDropDown,
}: NavItemProps) => (
    <>
        {menu.map((item, index) => (
            <li
                className="primary-nav__item"
                role="menuitem"
                key={`top-${item.id}-${index}`}
            >
                <Link
                    key={`top-type-${item.id}-${index}`}
                    to={item.url.replace('https://www.freshegg.co.uk', '')}
                    data-is-dropdown={item?.childMenuItems.length >= 1}
                    className="primary-nav__link"
                    aria-haspopup={item?.childMenuItems.length >= 1}
                    aria-expanded="false"
                    aria-controls={getJsHandle(item)}
                    onMouseOver={(evt) => handleSetActiveDropDown(evt, index)}
                    onFocus={(evt) => handleSetActiveDropDown(evt, index)}
                    onClick={(evt) => handleSetActiveDropDown(evt, index)}
                    activeClassName="is-active"
                >
                    {item.title}
                </Link>

                {item.childMenuItems.some(
                    (c) => c.menuItemBlockDisplay !== 'No block'
                ) && (
                    <div
                        className={`primary-nav__dropdown ${
                            typeof activeDropDown !== 'undefined' &&
                            index === activeDropDown
                                ? 'is-active'
                                : ''
                        }`}
                        id={getJsHandle(item)}
                    >
                        {item.childMenuItems
                            .filter((c) => c.menuItemBlockDisplay !== null)
                            .map((block, i) => (
                                <React.Fragment
                                    key={`childMenuItems-${block.id}-${i}`}
                                >
                                    {block.menuItemBlockDisplay ===
                                        'Primary block' && (
                                        <div
                                            className="primary-nav__dropdown__items"
                                            key={`primary-${block.id}-${i}`}
                                        >
                                            <Typography
                                                key={`primary-type-${block.id}-${i}`}
                                                component="span"
                                                variant="caps"
                                                className="primary-nav__dropdown__heading"
                                            >
                                                {block.title}
                                            </Typography>

                                            <ul
                                                className="primary-nav__dropdown__nav"
                                                role="menu"
                                            >
                                                {block.childMenuItems.map(
                                                    (subItem, subIndex) => (
                                                        <li
                                                            className="primary-nav__dropdown__item"
                                                            role="menuitem"
                                                            key={`child-menu-${subIndex}`}
                                                        >
                                                            <Link
                                                                to={subItem.url.replace(
                                                                    'https://www.freshegg.co.uk',
                                                                    ''
                                                                )}
                                                                className="primary-nav__dropdown__link"
                                                                key={`child-menu-type-${subIndex}`}
                                                            >
                                                                {subItem.title}
                                                            </Link>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    )}

                                    {block.menuItemBlockDisplay ===
                                        'Featured block' && (
                                        <div
                                            className="primary-nav__dropdown__intro"
                                            key={`feat-${block.id}`}
                                        >
                                            <Typography
                                                key={`feat-type-${block.id}`}
                                                component="span"
                                                variant="caps"
                                                className="primary-nav__dropdown__heading"
                                            >
                                                {block.title}
                                            </Typography>
                                            <Typography
                                                key={`feat-type-stand-${block.id}`}
                                                component="div"
                                                variant="body"
                                                className="primary-nav__dropdown__content"
                                            >
                                                {' '}
                                                <p>{block?.content}</p>
                                            </Typography>
                                            <TextLink
                                                url={block.url}
                                                text={block.linkText}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                    </div>
                )}
            </li>
        ))}
    </>
);

export const Header: FC<HeaderProps> = ({ siteTitle, menu }) => {
    const menuItems: any[] = useMemo(
        () => (menu && menu.length >= 1 ? flatListToHierarchical(menu) : []),
        [menu]
    );
    const menusWithDropDowns: any[] = useMemo(
        () =>
            menuItems.map((item, index) =>
                item.childMenuItems.length >= 1 ? index : null
            ),
        [menuItems]
    );

    const [isPinned, setIsPinned] = useState(false);

    const [activeDropDown, setActiveDropDown] = useState<number | undefined>(
        undefined
    );

    const clickRef = useRef<HTMLDivElement>();
    const timeOutRef = useRef<any>();

    useClickOutside(clickRef, () => setActiveDropDown(undefined));

    const handleSetActiveDropDown = (evt, index) => {
        // evt.preventDefault();
        setActiveDropDown(index);
    };

    const handleOnTop = () => {
        if (isBrowser) {
            document.body.classList.remove('header-pinned');
            setIsPinned(false);
        }
    };

    const handleOnNotTop = () => {
        if (isBrowser) {
            document.body.classList.add('header-pinned');
            setIsPinned(true);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 20 && !isPinned) {
                handleOnNotTop();
            } else if (window.scrollY <= 20 && isPinned) {
                handleOnTop();
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isPinned]);

    useEffect(() => {
        if (isBrowser) {
            if (typeof activeDropDown === 'undefined') {
                document.body.classList.remove('is-active-dropdown');
            } else if (menusWithDropDowns.includes(activeDropDown)) {
                document.body.classList.add('is-active-dropdown');
            }
        }
        return () => {
            if (isBrowser) {
                document.body.classList.remove('is-active-dropdown');
            }
        };
    }, [activeDropDown, menusWithDropDowns]);

    // Close drop down on route change
    useEffect(
        () =>
            globalHistory.listen(({ action }) => {
                if (action === 'PUSH') {
                    setActiveDropDown(undefined);
                    // Back to top on page change
                    handleOnTop();
                }
            }),
        [setActiveDropDown]
    );

    const handleOnEnter = () => {
        if (isBrowser) {
            if (timeOutRef.current) {
                clearTimeout(timeOutRef.current);
            }
        }
    };

    const handleOnLeave = () => {
        if (isBrowser) {
            timeOutRef.current = setTimeout(() => {
                setActiveDropDown(undefined);
            }, 800);
        }
    };

    return (
        <Headroom
            disableInlineStyles
            // onPin={handleOnTop}
            // onUnpin={handleOnNotTop}
        >
            <StyledHeader isPinned={isPinned} ref={clickRef}>
                <StyledHeaderContainer isPinned={isPinned}>
                    <StyledHeaderInner isPinned={isPinned}>
                        <StyledLogo
                            to="/"
                            className="header__logo"
                            title={siteTitle}
                        >
                            <StyledLogoSm isPinned={isPinned}>
                                <FeLogoEggsBlack />
                            </StyledLogoSm>
                            <StyledLogoLg isPinned={isPinned}>
                                <FeLogoBlack />
                            </StyledLogoLg>
                        </StyledLogo>

                        <StyledCallSm>
                            {activeDropDown !== undefined && (
                                <StyledClose
                                    id="js-close-dropdown"
                                    type="button"
                                    onClick={() => setActiveDropDown(undefined)}
                                >
                                    <span className="label">Close</span>
                                    <span className="close-icon">
                                        <Close />
                                    </span>
                                </StyledClose>
                            )}
                            {activeDropDown === undefined && (
                                <StyledTelephoneSm>
                                    <Telephone
                                        displayMode="small"
                                        className="telephone"
                                    />
                                </StyledTelephoneSm>
                            )}
                        </StyledCallSm>
                    </StyledHeaderInner>

                    <StyledHeadNav>
                        <StyledPrimaryNav
                            className="primary-nav"
                            role="navigation"
                            onMouseEnter={handleOnEnter}
                            onMouseLeave={handleOnLeave}
                        >
                            <ul className="primary-nav__nav" role="menu">
                                {menuItems?.length >= 1 && (
                                    <NavItems
                                        menu={menuItems}
                                        activeDropDown={activeDropDown}
                                        handleSetActiveDropDown={
                                            handleSetActiveDropDown
                                        }
                                    />
                                )}
                            </ul>
                        </StyledPrimaryNav>
                    </StyledHeadNav>

                    <StyledCallLg>
                        <Telephone displayMode="large" cssClass="" /> {isPinned}
                    </StyledCallLg>
                </StyledHeaderContainer>
            </StyledHeader>
        </Headroom>
    );
};

export default Header;
