import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Typography } from './Typography';
import { Button } from './Button';
import { Container } from './Container';
import { ThemeColorsKeys } from '../styles/tokens/colors';
import { getBackgroundColor } from '../utils/getBackgroundColor';

export interface CtaBannerProps {
    cssClass?: string;
    backgroundColor?: ThemeColorsKeys;
}

interface StyledCtaBannerProps {
    backgroundColor?: ThemeColorsKeys;
}

const StyledCtaBanner = styled.div<StyledCtaBannerProps>`
    ${({ theme: { colors, space, media }, backgroundColor }) => css`
        ${backgroundColor && getBackgroundColor(backgroundColor)}
        padding: ${space.xxl} 0;
        margin-top: ${space.xxl};
        @media (min-width: ${media.lg}) {
            padding: ${space.xxxl} 0;
            margin-top: ${space.xxxl};
        }
    `}
`;

const StyledCtaBannerBody = styled(Container)`
    ${({ theme: { media } }) => css`
        @media (min-width: ${media.lg}) {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    `}
`;

const StyledCtaBannerBodyInner = styled.div`
    ${({ theme: { colors } }) => css`
        color: ${colors.white.default};
    `}
`;

const StyledCtaBannerContent = styled(Typography)`
    ${({ theme: { space } }) => css`
        display: block;
        margin-top: ${space.md};
    `}
`;

const StyledCtaBannerHeading = styled(Typography)`
    ${({ theme: { colors } }) => css`
        max-width: 500px;
    `}
`;

const StyledCtaBannerLink = styled.a`
    ${({ theme: { colors } }) => css`
        color: ${colors.white.default};
        text-decoration: underline;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    `}
`;

const StyledCtaBannerAction = styled.div`
    ${({ theme: { maxMedia, space } }) => css`
        @media (max-width: ${maxMedia.lg}) {
            margin-top: ${space.lg};
        }
        @media (max-width: ${maxMedia.sm}) {
            width: 100%;
            button {
                width: 100%;
            }
        }
    `}
`;

const StyledCtaBannerActionBtn = styled(Button)`
    ${({ theme: { maxMedia } }) => css`
        @media (max-width: ${maxMedia.sm}) {
            width: 100%;
        }
    `}
`;

export const CtaBanner: FC<CtaBannerProps> = ({
    cssClass,
    backgroundColor,
    ...rest
}) => {
    // TODO GET Numbers from backend
    const telnumber = '01903 285900';

    // icttNum
    const clickToCall = (phoneNumber) =>
        `tel:${phoneNumber.replace(/\s/g, '')}`;
    return (
        <StyledCtaBanner
            backgroundColor={backgroundColor || 'accent1'}
            role="banner"
            {...rest}
        >
            <StyledCtaBannerBody>
                <StyledCtaBannerBodyInner>
                    <StyledCtaBannerHeading component="h2" variant="h2">
                        Do you have a challenge we can help you with?
                    </StyledCtaBannerHeading>
                    <StyledCtaBannerContent component="p" variant="lead">
                        Let's have a chat about it! Call us on{' '}
                        <StyledCtaBannerLink
                            className={`${cssClass || ''}`}
                            href={clickToCall(telnumber)}
                        >
                            <span>{telnumber}</span>
                        </StyledCtaBannerLink>
                    </StyledCtaBannerContent>
                </StyledCtaBannerBodyInner>
                <StyledCtaBannerAction>
                    <StyledCtaBannerActionBtn
                        hasNoArrow
                        variant="solid"
                        url="/contact-us"
                        text="Send us a message"
                    />
                </StyledCtaBannerAction>
            </StyledCtaBannerBody>
        </StyledCtaBanner>
    );
};
