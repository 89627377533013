import { createGlobalStyle, keyframes } from 'styled-components';
import { getResponsiveType } from '../utils';
import { getBackgroundColor } from '../utils';
import { colors, media, layers } from './tokens';

export const GlobalStyle = createGlobalStyle`
    *, *::before, *::after {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        border: 0;
    }
    *:focus {
        outline: none; // We'll roll own own focus
    }
    html, body {
        height: 100%;
    }
    body {
        -webkit-font-smoothing: antialiased;
    }
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }
    input, button, textarea, select {
        font: inherit;
    }
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }
    #root, #__next {
        isolation: isolate;
    }
    ul, ol {
        list-style: none;
    }
    a {
        &:not([href]):not([tabindex]):focus {
            outline: 0;
        }
    }

    a, button {
        color: currentColor; 
    }

    a[href^='tel:'] {
        text-decoration: none;
    }
    address {
        font-style: normal;
    }
    button {
        cursor: pointer;
        background: none;
        color: currentColor;
    }
    // Selection
    ::selection {
        
    }
    // Body
    body {
        ${getResponsiveType('body')}
        ${getBackgroundColor('white')}
        min-height: 100vh;
        width: 100%;
        min-width: 320px;
        /* padding-top: 118px; */
        accent-color: ${colors.accent1.default};

        &.is-active-dropdown {
            overflow: hidden;
            position: fixed;
    
            @media (min-width: ${media.md}) {
                overflow-y: visible;
                position: static;
            }
    
            &::before {
                content: '';
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);
                z-index: ${layers.bgOverlay};
                outline: 0;
                animation: fade-in 200ms 1 both;
    
                &:hover {
                    cursor: pointer;
                }
            }
        }

        #gatsby-focus-wrapper {
            padding-top: 118px;
        }
    }

    .sr-only {
        border: 0;
        clip: rect(0,0,0,0);
        -webkit-clip-path: inset(50%);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    // Apply z-index to the modal to position over the page content
    .react-micro-modal--portal {
        div:nth-child(1) {
            z-index: ${layers.modal};
        }
    }
`;

export default GlobalStyle;

// Global Keyframes
export const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;
