import { useEffect, MutableRefObject } from 'react';

export const useClickOutside = (
    ref: MutableRefObject<any>,
    callback: (any) => any
) => {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };
    useEffect(() => {
        const isBrowser = typeof window !== 'undefined';
        if (isBrowser) {
            document.addEventListener('click', handleClick);
        }
        return () => {
            if (isBrowser) {
                document.removeEventListener('click', handleClick);
            }
        };
    });
};
