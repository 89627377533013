import React from 'react';
import {
    useStaticQuery,
    graphql,
    PageProps,
    Script,
    ScriptStrategy,
} from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { Header } from './Header';
import { Footer } from './Footer';
import theme from '../styles/tokens';
import GlobalStyles from '../styles/GlobalStyles';
import { Main } from './Main';
import { CtaBanner } from './CtaBanner';
import { DataLayerWrapper } from './DataLayerWrapper';
import { ExperimentsContextProvider } from '../context/ExperimentsContext';
import { ExperimentsWrapper } from './ExperimentsWrapper';

const Layout: React.FC<PageProps> = ({
    location,
    data,
    children,
    serverData,
}) => {
    const layoutData = useStaticQuery(graphql`
        query SiteLayeoutQuery {
            site {
                siteMetadata {
                    title
                }
            }
            mainMenu: hatchContentMenuType(name: { eq: "Main" }) {
                menuItems {
                    ...HATCH_ContentMenuItemTypeFragment
                }
            }
            footerMenu: hatchContentMenuType(name: { eq: "Footer" }) {
                menuItems {
                    ...HATCH_ContentMenuItemTypeFragment
                }
            }
        }
    `);
    const { site, mainMenu, footerMenu } = layoutData;

    return (
        <ExperimentsContextProvider>
            <GlobalStyles />
            <ThemeProvider theme={theme}>
                <ExperimentsWrapper data={data}>
                    <HubspotProvider>
                        <Script
                            strategy={ScriptStrategy.postHydrate}
                            type="text/javascript"
                            src="https://secure.acor1sign.com/js/216287.js"
                        />
                        <>
                            <noscript
                                key="noscript-acor1sign"
                                dangerouslySetInnerHTML={{
                                    __html: `<img src="https://secure.acor1sign.com/216287.png" style="display:none;">`,
                                }}
                            />
                        </>
                        <DataLayerWrapper
                            data={data}
                            serverData={serverData}
                            location={location}
                        >
                            <Header
                                siteTitle={site.siteMetadata.title}
                                menu={mainMenu.menuItems}
                            />
                            <Main>{children}</Main>
                        </DataLayerWrapper>
                        <CtaBanner />
                        <Footer
                            menu={footerMenu.menuItems}
                            pathname={location?.pathname}
                        />
                    </HubspotProvider>
                </ExperimentsWrapper>
            </ThemeProvider>
        </ExperimentsContextProvider>
    );
};
export default Layout;
