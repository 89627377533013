import React, { FC, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { ThemeColorsKeys } from '../styles/tokens/colors';
import { hideOnPrint } from '../styles/utils';

interface IconButtonProps {
    a11yTitle: string;
    id?: string;
    className?: string;
    onClick?: any;
    disabled?: boolean;
    type?: any;
    color?: ThemeColorsKeys;
    eventClickSection?: string;
}

interface IconButtonStyleProps {
    color?: ThemeColorsKeys;
}

const IconButtonStyles = styled.button<IconButtonStyleProps>`
    ${({ theme: { colors }, color }) => css`
        background-color: transparent;
        cursor: pointer;

        ${hideOnPrint}

        ${color &&
        css`
            color: ${colors[color].default};
        `}

    &:hover {
            color: ${colors.accent2.default};
        }
    `};
`;

export const IconButton: FC<React.PropsWithChildren<IconButtonProps>> =
    forwardRef(
        (
            {
                children,
                a11yTitle,
                id,
                className,
                onClick,
                disabled = false,
                type = 'button',
                color,
                eventClickSection,
                ...rest
            },
            ref
        ) => {
            if (!a11yTitle && !children) {
                return null;
            }

            const handleClick = () => {
                if (onClick) {
                    onClick();
                }
            };

            return (
                <IconButtonStyles
                    ref={ref}
                    id={id}
                    className={className}
                    onClick={handleClick}
                    aria-label={a11yTitle}
                    disabled={disabled}
                    aria-disabled={disabled}
                    type={type}
                    color={color}
                    eventName="cta button"
                    eventClickSection={eventClickSection}
                    {...rest}
                >
                    {children}
                </IconButtonStyles>
            );
        }
    );
